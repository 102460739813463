import React, { useState, useRef } from "react";
import DatePicker from "react-date-picker";
import { inputCurrency } from "../helpers";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";

const TransactionForm = (props) => {
  const { handleSubmit, register, errors, control } = useForm();
  const onSubmit = (values) => props.addTransaction(values);

  const currentDate = props.currentTransaction.date
    ? new Date(
        moment.utc(props.currentTransaction.date).format("MM/DD/YYYY")
      )
    : new Date(moment().format("MM/DD/YYYY"));

  const [showNewNameDefault, setShowNewNameDefault] = useState(!props.contacts.length ? true : false);
  const [showNewName, setShowNewName] = useState(showNewNameDefault);
  const newNameInput = useRef(null);

  const [showNewCategoryDefault, setShowNewCategoryDefault] = useState(!props.categories.length ? true : false);
  const [showNewCategory, setShowNewCategory] = useState(showNewCategoryDefault);
  const newCategoryInput = useRef(null);

  const id = props.currentTransaction.id || 0;
  const defaultAmount = props.currentTransaction.amount ? inputCurrency(props.currentTransaction.amount) : '';
  const [filteredContacts, setFilteredContacts] = useState(props.contacts.filter(contact => contact.type === 1));
  const [filteredCategories, setFilteredCategories] = useState(props.categories.filter(category => category.type === 1));

  React.useEffect(() => {
    if (showNewCategory) {
      newCategoryInput.current.focus();
    }
  }, [showNewCategory]);
  
  React.useEffect(() => {
    if (showNewName) {
      newNameInput.current.focus();
    }
  }, [showNewName]);

  function typeChange( newType ) {
    const currentContacts = props.contacts.filter(contact => contact.type === newType);
    if(currentContacts.length === 0) {
      setShowNewNameDefault(true);
      setShowNewName(true);
    } else {
      setShowNewNameDefault(false);
      setShowNewName(false);
    }
    setFilteredContacts( currentContacts );

    const currentCategories = props.categories.filter(category => category.type === newType);
    if(currentCategories.length === 0) {
      setShowNewCategoryDefault(true);
      setShowNewCategory(true);
    } else {
      setShowNewCategoryDefault(false);
      setShowNewCategory(false);
    }
    setFilteredCategories( currentCategories );
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={props.closeModal}>
          &times;
        </span>
        <form method="get" className="form" onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <div className="radios">
                <input
                  type="radio"
                  name="type"
                  id="income"
                  value="1"
                  defaultChecked={props.currentTransaction.type === 1 || 1}
                  onChange={() => { typeChange(1) }}
                  ref={register({ required: true })}
                />
                <label htmlFor="income">Income</label>
                <input
                  type="radio"
                  name="type"
                  id="expense"
                  value="2"
                  defaultChecked={props.currentTransaction.type === 2}
                  onChange={() => { typeChange(2) }}
                  ref={register({ required: true })}
                />
                <label htmlFor="expense">Expense</label>
                <input
                  type="radio"
                  name="type"
                  id="tax"
                  value="3"
                  defaultChecked={props.currentTransaction.type === 3}
                  onChange={() => { typeChange(3) }}
                  ref={register({ required: true })}
                />
                <label htmlFor="tax">Tax</label>
              </div>
              {errors.type && (
                <div className="error">Type of transaction is required</div>
              )}
            </li>
            <li>
              <label htmlFor="date">Date</label>
              <Controller
                as={DatePicker}
                name="date"
                control={control}
                format="MM/dd/yyyy"
                defaultValue={currentDate}
                clearIcon={null}
                className="datepicker"
                rules={{ required: true }}
              />
              {errors.date && <div className="error">Date is required</div>}
            </li>
            <li>
              <label htmlFor="name">Name</label>
              {showNewName ? (
                <input
                  name="name"
                  id="name"
                  className="text"
                  ref={(ref) => {
                    newNameInput.current = ref;
                    register(ref, {
                      required: true,
                      maxLength: 100
                    });
                  }}
                ></input>
              ) : (
                <select
                  name="name"
                  id="name"
                  className="select"
                  defaultValue={props.currentTransaction.contactId}
                  ref={register({ required: true })}
                >
                  <option></option>
                  {filteredContacts.map((client) => {
                    return (
                      <option value={client.id} key={client.id}>
                        {client.name}
                      </option>
                    );
                  })}
                </select>
              )}
              <div className="add">
                {showNewName && !showNewNameDefault && (
                  <button
                    className="btn-sm btn-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowNewName(false);
                    }}
                  >
                    Cancel
                  </button>
                )}
                {!showNewName && !showNewNameDefault && (
                  <button
                    className="btn-sm btn-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowNewName(true);
                    }}
                  >
                    Add New +
                  </button>
                )}
              </div>
              {errors.name?.type === "required" && (
                <div className="error">Name is required</div>
              )}
              {errors.name?.type === "maxLength" && (
                <div className="error">Name cannot exceed 100 characters</div>
              )}
            </li>
            <li>
              <label htmlFor="description">Description</label>
              <input
                type="text"
                id="description"
                name="description"
                className="text"
                defaultValue={props.currentTransaction.description}
                ref={register}
              />
            </li>
            <li>
              <label htmlFor="category">Category</label>
              {showNewCategory ? (
                <input
                  name="category"
                  id="category"
                  className="text"
                  ref={(ref) => {
                    newCategoryInput.current = ref;
                    register(ref, { required: true, maxLength: 100 });
                  }}
                ></input>
              ) : (
                <select
                  name="category"
                  id="category"
                  className="select"
                  defaultValue={props.currentTransaction.categoryId}
                  ref={register({ required: true })}
                >
                  <option></option>
                  {filteredCategories.map((category) => {
                    return (
                      <option value={category.id} key={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              )}
              <div className="add">
                {showNewCategory && !showNewCategoryDefault && (
                  <button
                    className="btn-sm btn-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowNewCategory(false);
                    }}
                  >
                    Cancel
                  </button>
                )}
                {!showNewCategory && !showNewCategoryDefault && (
                  <button
                    className="btn-sm btn-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowNewCategory(true);
                    }}
                  >
                    Add New +
                  </button>
                )}
              </div>
              {errors.category?.type === "required" && (
                <div className="error">Category is required</div>
              )}
              {errors.category?.type === "maxLength" && (
                <div className="error">Category cannot exceed 100 characters</div>
              )}
            </li>
            <li>
              <label htmlFor="amount">Amount</label>
              <input
                type="text"
                id="amount"
                name="amount"
                className="text"
                defaultValue={defaultAmount}
                onBlur={(e) => (e.target.value = inputCurrency(e.target.value))}
                ref={register({ required: true })}
              />
              {errors.amount && <div className="error">Amount is required</div>}
            </li>
          </ul>
          <div className="split">
            <div className="col">
              <button className="btn">Submit</button>
            </div>
            <div className="col">
              {id !== 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    props.deleteTransaction(id);
                  }}
                  className="btn btn-warning"
                >
                  Delete
                </button>
              )}
            </div>
          </div>
          <input type="hidden" name="id" value={id} ref={register} />
        </form>
      </div>
    </div>
  );
};

export default TransactionForm;
