import React, { Component } from "react";
import { sum, displayCurrency } from "../helpers";
import './Totals.scss';

class Totals extends Component {
  render() {
    const activeClient = this.props.activeClient;
    const activeClientType = this.props.activeClientType;
    const activeCategory = this.props.activeCategory;
    const activeCategoryType = this.props.activeCategoryType;

    let transactions = (activeClient) ? this.props.transactions.filter(transaction => transaction.contactId === activeClient) : this.props.transactions;
    if(activeCategory) transactions = transactions.filter(transaction => transaction.categoryId === activeCategory);

    const income = sum(transactions.filter(transaction => transaction.type === 1));
    const expenses = sum(transactions.filter(transaction => transaction.type === 2));
    const balance = income - expenses;
    const taxes = sum(transactions.filter(transaction => transaction.type === 3));

    return (
      <div className="totals">
        {((activeClientType === 1 || !activeClientType) && (activeCategoryType === 1 || !activeCategoryType)) && (
          <div className="total">Income: {displayCurrency(income)}</div>
        )}
        {((activeClientType === 2 || !activeClientType) && (activeCategoryType === 2 || !activeCategoryType)) && (
          <div className="total">Expenses: {displayCurrency(expenses)}{activeCategoryType}</div>
        )}
        {(!activeClientType && !activeCategoryType) && (
          <div className="total">Profit: {displayCurrency(balance)}</div>
        )}
        {((activeClientType === 3 || !activeClientType) && (activeCategoryType === 3 || !activeCategoryType)) && (
          <div className="total">Taxes: {displayCurrency(taxes)}</div>
        )}
      </div>
    );
  }
}

export default Totals;