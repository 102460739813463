import React, {Component} from 'react';

class YearSwitch extends Component {
  yearOptions = () => {
    let currentYear = new Date().getFullYear() - 1;
    let options = [];
    for (currentYear; currentYear >= this.props.earliest; currentYear--) {
      options.push(
        <option value={currentYear} key={currentYear}>
          {currentYear}
        </option>
      );
    }
    return options;
  };

  render() {
    return (
      <footer className="footer">
        <form action="" method="get" className="form">
          <ul>
            <li>
              <select
                name="year"
                id="year"
                className="year-switch select"
                value={this.props.activeYear}
                onChange={e => this.props.setYear(e)}
              >
                <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                {this.yearOptions()}
              </select>
            </li>
          </ul>
        </form>
      </footer>
    );
  }
}

export default YearSwitch;