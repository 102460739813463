import React from "react";

const Error = (props) => {
    return (
      <div className="warning pad">
        {props.error}
      </div>
    );
};

export default Error;