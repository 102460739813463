import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from '../firebase.js';

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailSent: false,
      error: null
    };
  }

  handleChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  resetPass = async (e) => {
    e.preventDefault();
    let email = this.state.email;

    if(!email) {
      this.setState({ error: "You must provide a email" });
      return;
    }

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setState({ error: null });
        this.setState({ emailSent: true });
        setTimeout(() => {
          this.setState({ emailSent: false });
        }, 3000);
      })
      .catch((error) => {
        let errorMessage = error.message;
        this.setState({ error: errorMessage });
      });

  }

  render() {
    let error = this.state.error;
    return (
      <div className="login">
        <h1>Revelry</h1>
        {error !== null && (
          <div>
            <span className="error">{error}</span>
          </div>
        )}
        {this.state.emailSent && (
          <div>
            <span className="error">An email has been sent to you!</span>
          </div>
        )}
        <form className="form">
          <ul>
            <li>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                className="text"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </li>
          </ul>
          <input
            type="submit"
            value="Reset"
            className="btn btn-warning"
            onClick={(e) => this.resetPass(e)}
          />
        </form>
        <p>
          Already have an account? <Link to="/">Sign In</Link>
        </p>
      </div>
    );
  }
}

export default Reset;