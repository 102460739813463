import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Terms from './Terms';
import Privacy from './Privacy';

const Accept = (props) => {
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (values) => {
    props.acceptTerms();
  }

  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);

  const handleTerms = (e) => {
    e.preventDefault(); 
    setTerms(!terms);
  }

  const handlePrivacy = (e) => {
    e.preventDefault();
    setPrivacy(!privacy);
  }

  return (
    <div className="modal">
      <div className="modal-content mod-accept">
        {(!terms && !privacy) && (
          <div>
            <h2>Terms &amp; Conditions and Privacy Policy</h2>
            <form method="get" onSubmit={handleSubmit(onSubmit)}>
              <p className="accept">
                <input type="checkbox" name="accept" className="check" ref={register({ required: true })} />
                <label htmlFor="accept">I acknowledge that I have read and agree to the <button className="btn-link" onClick={handleTerms}>Terms &amp; Conditions</button> and <button className="btn-link" onClick={(e) => { e.preventDefault(); setPrivacy(true); }}>Privacy Policy</button>.</label>
              </p>
              {errors.accept && <div className="error">You must accept these to continue</div>}
              <button className="btn">Accept</button>
            </form>
          </div>
        )}
        {terms && (
          <Terms handleTerms={handleTerms} />
        )}
        {privacy && (
          <Privacy handlePrivacy={handlePrivacy} />
        )}
      </div>
    </div>
  )
}

export default Accept;