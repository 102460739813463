import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from '../firebase.js';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      display: '',
      error: null
    };
  }

  handleChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;

    if(name === 'display') {
      value = value.replace(/(<([^>]+)>)/gi, "");
    }
    
    this.setState({ [name]: value });
  }

  create = async (e) => {
    e.preventDefault();
    let email = this.state.email;
    let password = this.state.password;
    let display = this.state.display;

    if(!display) {
      this.setState({ error: 'You must provide a display name.' });
      return;
    }

    try {
      await auth.createUserWithEmailAndPassword(email, password);

      // save username to the database
      this.props.insertUser({ display: this.state.display });

    } catch (error) {
      let errorMessage = error.message;
      this.setState({ error: errorMessage });
      //console.log(error);
    }

  }

  render() {
    let error = this.state.error;
    return (
      <div className="login">
        <h1>Revelry</h1>
        {error !== null && (
          <div>
            <span className="error">{error}</span>
          </div>
        )}
        <form className="form">
          <ul>
            <li>
              <label htmlFor="display">Display name</label>
              <input type="display" name="display" className="text" value={this.state.display} onChange={this.handleChange} />
            </li>
            <li>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" className="text" value={this.state.email} onChange={this.handleChange} />
            </li>
            <li>
              <label htmlFor="password">Password</label>
              <input type="password" name="password" className="text" value={this.state.password} onChange={this.handleChange} />
            </li>
          </ul>
          <input type="submit" value="Register" className="btn btn-warning" onClick={(e) => this.create(e)} />
        </form>
        <p>Already have an account? <Link to="/">Sign In</Link></p>
      </div>
    )
  }
}

export default Register;