import moment from "moment";

export function displayDate(stamp) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Au", "Sep", "Oct", "Nov", "Dec"];
  var date = new Date(stamp);
  var month = monthNames[date.getMonth()];
  var day = date.getDate();
  return month + ' ' + day + ', ' + date.getFullYear();
}

export function inputDate(date) {
  if(!moment.isDate(date)) return false;
  var month = ('0' + (date.getMonth() + 1)).slice(-2);
  var day = ('0' + date.getDate()).slice(-2);
  return date.getFullYear() + '-' + month + '-' + day;
}

export function displayCurrency(amount) {
  if (typeof amount === 'string') amount = amount.replace('$', '');
  amount = parseFloat(amount);
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
}

export function inputCurrency(amount) {
  if (typeof amount === 'string') {
    amount = amount.replace('$', '');
    amount = amount.replace(',', '');
  }
  if(!amount) return amount;
  amount = parseFloat(amount);
  if(isNaN(amount)) amount = 0;
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
}

export function sum(transactions) {
  var total = transactions.reduce(function (prev, cur) {
    return prev + cur.amount;
  }, 0);
  return total;
}

export function trimString(str, length, ending) {
  if (length == null) {
    length = 30;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}