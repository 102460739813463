import React, { Component } from "react";
import { Link } from "react-router-dom";
import GoogleLogo from '../icons/google.svg';
import { auth } from '../firebase.js';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: null
    };
  }

  handleChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  authenticate = (e) => {
    e.preventDefault();
    let email = this.state.email;
    let password = this.state.password;

    auth.signInWithEmailAndPassword(email, password).catch(error => {
      let errorMessage = error.message;
      this.setState({ error: errorMessage });
      //console.error("Error signing in with password and email", error);
    });
  }

  render() {
    let error = this.state.error;
    return (
      <div className="login">
        <h1>Revelry</h1>
        {error !== null && (
          <div>
            <span className="error">{error}</span>
          </div>
        )}
        <form className="form">
          <ul>
            <li>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" className="text" value={this.state.email} onChange={this.handleChange} />
            </li>
            <li>
              <label htmlFor="password">Password</label>
              <input type="password" name="password" className="text" value={this.state.password} onChange={this.handleChange} />
            </li>
          </ul>
          <input type="submit" value="Sign In" className="btn btn-warning" onClick={(e) => this.authenticate(e)} />
        </form>
        <button onClick={this.props.login} className="btn btn-warning btn-google">
          <img src={GoogleLogo} alt="Google" /> Sign in with Google
        </button>
        <p>No account? <Link to="/register">Register</Link></p>
        <p><Link to="/reset">Forgot password?</Link></p>
      </div>
    )
  }
}

export default Login;