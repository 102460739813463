import React, { Component} from 'react';
import { displayCurrency, trimString } from "../helpers";
import moment from "moment";

class Transaction extends Component {
  render() {
    return (
      <tr key={this.props.transaction.id} onClick={this.props.editTransaction}>
        <td>{moment.utc(this.props.transaction.date).format('MMM D, YYYY')}</td>
        <td><button onClick={(e) => this.props.filterByClient(e, this.props.transaction)} className="button">{trimString(this.props.transaction.name)}</button></td>
        <td>{trimString(this.props.transaction.description)}</td>
        <td><button onClick={(e) => this.props.filterByCategory(e, this.props.transaction)} className="button">{trimString(this.props.transaction.category)}</button></td>
        <td>{displayCurrency(this.props.transaction.amount)}</td>
      </tr>
    );
  }
}

export default Transaction;