import React, {Component} from 'react';
import { Switch, Route } from "react-router-dom";
import Totals from './Totals';
import Transactions from './Transactions';
import TransactionForm from './TransactionForm';
import Login from './Login';
import Register from "./Register";
import Reset from "./Reset";
import Nav from './Nav';
import Error from './Error';
import YearSwitch from './YearSwitch';
import { inputDate } from "../helpers";
import { auth, provider } from '../firebase.js';
import moment from 'moment';
import Accept from './Accept';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      contacts: [],
      categories: [],
      insertModal: false,
      activeTab: 1,
      currentTransaction: {},
      activeClient: false,
      activeClientType: null,
      activeYear: new Date().getFullYear(),
      earliestYear: new Date().getFullYear(),
      user: null,
      userData: {},
      token: null,
      login: false,
      fetched: false,
      error: false,
      modal: false
    };

  }

  componentDidMount() {  
    const currentMode = localStorage.getItem('mode');

    if (currentMode === 'dark') {
      document.body.classList.toggle('dark-mode');
    } else if (currentMode === 'light') {
      document.body.classList.toggle("light-mode");
    } else {
      localStorage.setItem('mode', 'dark');
    }

    this.persistLogin();
  }

  persistLogin = () => {
    // Check if user is already logged in with firebase
    auth.onAuthStateChanged(async(user) => {
      if (user) {
        this.setState({ user });

        const token = await user.getIdToken();
        this.setState({ token, login: false });
        this.getUserdata();
        this.getAllData();
      } else {

        this.setState({ login: true });
        
      }
    });
  }
  
  getUserdata = () => {
    fetch(process.env.REACT_APP_API + "/user", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.token}`,
      },
    })
    .then((response) => {
      return response.json();
    })
    .then((userData) => {

      if(Object.keys(userData).length === 0 && userData.constructor === Object) {

        this.setState({ userData: {terms: 0} });

      } else {

        this.setState({ userData });

        if (userData.terms === 0) {
          document.body.classList.add("modal-open");
        }

      }
    });
  }

  getAllData = () => {
    this.getTransactions();
    this.getEarliestYear();
    this.getContacts();
    this.getCategories();
  }

  getTransactions = () => {
    fetch(process.env.REACT_APP_API + "/transactions/?year=" + this.state.activeYear, 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.token}`,
          }
        }
      )
      .then(response => {
        return response.json();
      })
      .then(transactions => {
        transactions.map(transaction => {
          return transaction.date = moment.utc(transaction.date).format("YYYY-MM-DD");
        });
        this.setState({ 
          transactions, 
          fetched: true 
        });
      });
  };

  getContacts = () => {
    fetch(process.env.REACT_APP_API + "/contacts/", 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.token}`,
          }
        }
      )
      .then(response => {
        return response.json();
      })
      .then(contacts => this.setState({ contacts }));
  };

  getCategories = () => {
    fetch(process.env.REACT_APP_API + "/categories/",
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.token}`,
          }
        }
      )
      .then(response => {
        return response.json();
      })
      .then(categories => this.setState({ categories }));
  };

  getEarliestYear = () => {
    fetch(process.env.REACT_APP_API + "/year/", 
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.token}`,
          }
        }
      )
      .then(response => {
        return response.json();
      })
      .then(data =>
        this.setState({ earliestYear: new Date(data.date).getFullYear() })
      );
  };

  openModal = () => {
    this.setState({ modal: 'transaction' });
    document.body.classList.add('modal-open');
  };

  closeModal = () => {
    this.setState({ modal: false, currentTransaction: {} });
    document.body.classList.remove('modal-open');
  };

  acceptTerms = () => {
    fetch(process.env.REACT_APP_API + "/user/terms", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
          let userData = this.state.userData;
          userData.terms = 1;
          this.setState({ userData });
          document.body.classList.remove("modal-open");
      });
  }

  editTransaction = editId => {
    this.openModal();
    const transaction = this.state.transactions.filter(
      transaction => transaction.id === editId
    )[0];
    transaction.id = editId;
    this.setState({ currentTransaction: transaction });
  };

  addTransaction = async (transaction) => {
    let amount = transaction.amount;
    if (typeof amount === "string")
      amount = amount.replace("$", "").replace(",", "");
    amount = parseFloat(amount);
    transaction.amount = amount;
    transaction.date = inputDate(transaction.date);
    transaction.type = parseInt(transaction.type);
    transaction.id = parseInt(transaction.id);

    if (isNaN(transaction.id) || isNaN(transaction.type) || !transaction.date || isNaN(transaction.amount)) {
      this.setState({ error: 'An error occured. The transaction could not be added.' });
      return;
    }

    const name = parseInt(transaction.name);
    const category = parseInt(transaction.category);

    if(typeof transaction.description !== 'string') {
      this.setState({ error: 'An error occured. The transaction could not be added.' });
      return;
    }

    transaction.description = transaction.description.replace(/(<([^>]+)>)/gi, "");

    if(isNaN(name)) {
      // Strip tags
      transaction.name = transaction.name.replace(/(<([^>]+)>)/gi, "");

      let contactResponse = await fetch(
        process.env.REACT_APP_API + "/contact/add",
        {
          method: "POST",
          body: JSON.stringify(transaction),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      ).then((res) => res.json());

      transaction.name = contactResponse.insertId;
    }

    if(isNaN(category)) {
      // Strip tags
      transaction.category = transaction.category.replace(/(<([^>]+)>)/gi, "");

      let categoryResponse = await fetch(
        process.env.REACT_APP_API + "/category/add",
        {
          method: "POST",
          body: JSON.stringify(transaction),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      ).then((res) => res.json());

      transaction.category = categoryResponse.insertId;
      //console.log(transaction);
    }

    fetch(process.env.REACT_APP_API + "/add", {
      method: "POST",
      body: JSON.stringify(transaction),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.token}`,
      },
    }).then(
      function (response) {
        response.json().then(
          function (data) {
            this.getAllData();
            this.closeModal();
          }.bind(this)
        );
      }.bind(this)
    );
  };;

  filterByClient = (e, transaction) => {
    e.stopPropagation();
    this.setState({ activeClient: transaction.contactId, activeClientType: transaction.type });
  };

  clearContactFilter = () => {
    this.setState({ activeClient: false, activeClientType: null });
  };

  filterByCategory = (e, transaction) => {
    e.stopPropagation();
    this.setState({ activeCategory: transaction.categoryId, activeCategoryType: transaction.type });
  };

  clearCategoryFilter = () => {
    this.setState({ activeCategory: false, activeCategoryType: null });
  };

  deleteTransaction = (id) => {
    var confirm = window.confirm("Are you sure?");
    if (confirm) {
      fetch(process.env.REACT_APP_API + "/delete", {
        method: "POST",
        body: JSON.stringify({ id }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.token}`,
        }
      }).then(
        function(response) {
          response.json().then(
            function(data) {
              this.getTransactions();
              this.setState({ modal: false, currentTransaction: {} });
            }.bind(this)
          );
        }.bind(this)
      );
    }
  };

  setYear = e => {
    this.setState({ activeYear: parseInt(e.target.value) }, () => this.getTransactions());
  };

  clearYearFilter = () => {
    this.setState(
      { activeYear: new Date().getFullYear() }, 
      () => this.getTransactions()
    );
  };

  login = () => {
      auth
      .signInWithPopup(provider)
      .then(
        function (result) {
          var user = result.user;
          this.setState({ 
            user, 
            login: false 
          });
        }.bind(this)
      )
      .catch(
        function (error) {
          console.log(error);
        }
      );
  }

  logout = () => {
    auth
    .signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
  }

  adjustMode = () => {
    let newMode = false;
    const currentMode = localStorage.getItem('mode');
    if(currentMode) {
      newMode = (currentMode === 'light') ? 'dark' : 'light';
    } else {
      newMode = 'dark';
    }

    localStorage.setItem('mode', newMode);
    document.body.classList.remove(currentMode + '-mode');
    document.body.classList.add(newMode + '-mode');

  }

  insertUser = async(userData) => {

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        this.setState({ token });

        fetch(process.env.REACT_APP_API + "/user/add", {
          method: "POST",
          body: JSON.stringify(userData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.token}`,
          },
        })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
            userData.terms = 0;
            userData.uid = user.uid;
            this.setState({ 
              userData,
              login: false
            })
        });

      }
    });

  };

  render() {
    const activeClientName = this.state.contacts.filter(
      contact => contact.id === this.state.activeClient
    )[0];
    const activeCategoryName = this.state.categories.filter(
      category => category.id === this.state.activeCategory
    )[0];
    const currentYear = new Date().getFullYear();
    return (
      <div className="wrap">
        {this.state.login && (
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Login {...props} login={this.login} />}
            />
            <Route
              exact
              path="/register"
              render={(props) => (
                <Register {...props} insertUser={this.insertUser} />
              )}
            />
            <Route
              exact
              path="/reset"
              render={(props) => (
                <Reset {...props} insertUser={this.insertUser} />
              )}
            />
            {/* <Route exact path="/totals" component={ClientTotals} /> */}
          </Switch>
        )}
        {!this.state.login && this.state.user === null && (
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        )}
        {this.state.user && (
          <div className="app">
            <Nav
              openModal={this.openModal}
              logout={this.logout}
              adjustMode={this.adjustMode}
              user={this.state.user}
              userData={this.state.userData}
            />
            {this.state.modal === "transaction" && (
              <TransactionForm
                addTransaction={this.addTransaction}
                closeModal={this.closeModal}
                contacts={this.state.contacts}
                categories={this.state.categories}
                currentTransaction={this.state.currentTransaction}
                deleteTransaction={this.deleteTransaction}
              />
            )}
            {this.state.userData.terms === 0 && (
              <Accept acceptTerms={this.acceptTerms} />
            )}
            <Totals
              transactions={this.state.transactions}
              activeClient={this.state.activeClient}
              activeClientType={this.state.activeClientType}
              activeCategory={this.state.activeCategory}
              activeCategoryType={this.state.activeCategoryType}
            />
            <div className="add-mb">
              <button className="btn btn-add" onClick={this.openModal}>
                + Add Transaction
              </button>
            </div>
            {this.state.error && <Error error={this.state.error} />}
            {activeClientName && (
              <div className="warning">
                Currently viewing {activeClientName.name} -{" "}
                <button onClick={this.clearContactFilter}>View All</button>
              </div>
            )}
            {activeCategoryName && (
              <div className="warning">
                Currently viewing {activeCategoryName.name} -{" "}
                <button onClick={this.clearCategoryFilter}>View All</button>
              </div>
            )}
            {this.state.activeYear !== currentYear && (
              <div className="warning">
                Currently viewing {this.state.activeYear} -{" "}
                <button onClick={this.clearYearFilter}>View Current</button>
              </div>
            )}
            <Transactions
              transactions={this.state.transactions}
              editTransaction={this.editTransaction}
              currentTransaction={this.state.currentTransaction}
              filterByClient={this.filterByClient}
              filterByCategory={this.filterByCategory}
              activeClient={this.state.activeClient}
              activeCategory={this.state.activeCategory}
              clearContactFilter={this.clearContactFilter}
              clearCategoryFilter={this.clearCategoryFilter}
              fetched={this.state.fetched}
            />
            {this.state.earliestYear !== new Date().getFullYear() && (
              <YearSwitch
                setYear={this.setYear}
                activeYear={this.state.activeYear}
                earliest={this.state.earliestYear}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default App;
