import React, { Component } from "react";
import { ReactComponent as AdjustIcon } from '../icons/adjust.svg';
import { ReactComponent as SupportIcon } from '../icons/lifebuoy.svg';

class Nav extends Component {
  render() {
    const displayName = this.props.user.displayName || this.props.userData.display;
    return (
      <div className="site-nav">
        <div className="profile-nav">
          <a
            href="mailto:detour@hybridvigordesign.com?subject=Revelry Support"
            className="btn btn-mode"
          >
            <SupportIcon />
          </a>
          <button onClick={this.props.adjustMode} className="btn btn-mode">
            <AdjustIcon />
          </button>
          <div className="profile-name">{displayName}</div>
          {this.props.user.photoURL && (
            <img
              src={this.props.user.photoURL}
              className="profile-img"
              alt="user"
            />
          )}
          <button onClick={this.props.logout} className="btn">
            Log Out
          </button>
        </div>
        <button className="btn btn-add" onClick={this.props.openModal}>
          + Add Transaction
        </button>
      </div>
    );
  }
}

export default Nav;