import React, { Component } from 'react';
import Transaction from './Transaction';
import './Transactions.scss';

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1
    };

  }

  changeTab = (e, index) => {
    e.preventDefault();
    this.props.clearContactFilter()
    this.props.clearCategoryFilter()
    this.setState({ activeTab: index });
  };

  render() {
    const activeClient = this.props.activeClient;
    const activeCategory = this.props.activeCategory;
    let transactions = (activeClient) ? this.props.transactions.filter(transaction => transaction.contactId === activeClient) : this.props.transactions;
    if(activeCategory) transactions = transactions.filter(transaction => transaction.categoryId === activeCategory);
    const transactionsInc = transactions.filter(transaction => transaction.type === 1);
    const transactionsExp = transactions.filter(transaction => transaction.type === 2);
    const transactionsTax = transactions.filter(transaction => transaction.type === 3);
    return (
      <div className="transaction-tabs">
        <div className="tabnav">
          <ul>
            <li><a href="#income" className={this.state.activeTab === 1 ? 'active' : ''} onClick={(e) => this.changeTab(e, 1)}>Income</a></li>
            <li><a href="#expenses" className={this.state.activeTab === 2 ? 'active' : ''} onClick={(e) => this.changeTab(e, 2)}>Expenses</a></li>
            <li><a href="#taxes" className={this.state.activeTab === 3 ? 'active' : ''} onClick={(e) => this.changeTab(e, 3)}>Taxes</a></li>
          </ul>
        </div>
        <div className="tabs">
          <div className={"tab " + (this.state.activeTab === 1 ? 'active' : '')} id="income">
            <table className="listings">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Category</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {this.props.fetched ? 
                  transactionsInc.map(transaction => {
                    return <Transaction 
                      transaction={transaction} 
                      filterByClient={this.props.filterByClient} 
                      filterByCategory={this.props.filterByCategory}
                      editTransaction={ (e) => this.props.editTransaction(transaction.id)} 
                      key={transaction.id} 
                    />;
                  }) :
                  <tr>
                    <td colSpan="6"><div className="lds-ripple"><div></div><div></div></div></td>
                  </tr>
                }
                {this.props.fetched && transactionsInc.length === 0 &&
                  <tr>
                    <td colSpan="6">No transactions yet.</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div className={"tab " + (this.state.activeTab === 2 ? 'active' : '')} id="expenses">
            <table className="listings">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name </th>
                  <th>Description</th>
                  <th>Category</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {transactionsExp.map(transaction => {
                  return <Transaction transaction={transaction} filterByClient={this.props.filterByClient} filterByCategory={this.props.filterByCategory} editTransaction={(e) => this.props.editTransaction(transaction.id)} key={transaction.id} />;
                })}
                {transactionsExp.length === 0 &&
                  <tr>
                    <td colSpan="6">No transactions yet.</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div className={"tab " + (this.state.activeTab === 3 ? 'active' : '')} id="taxes">
            <table className="listings">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name </th>
                  <th>Description</th>
                  <th>Category</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {transactionsTax.map(transaction => {
                  return <Transaction transaction={transaction} filterByClient={this.props.filterByClient} filterByCategory={this.props.filterByCategory} editTransaction={(e) => this.props.editTransaction(transaction.id)} key={transaction.id} />;
                })}
                {transactionsTax.length === 0 &&
                  <tr>
                    <td colSpan="6">No transactions yet.</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Transactions;